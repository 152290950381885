import React from 'react';
import styles from './styles.module.scss';

function Footer() {
    return(
        <div className={styles.container}>
            Developed with ☕️ by <a href="https://linktr.ee/leonardo_schmidt" target='__blank'>Leonardo Schmidt</a>
        </div>
    )
}

export default Footer;
